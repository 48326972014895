import React from 'react'
import './TopLevel.css'
function TopLevel() {
  function redirect(){

  }
  return (
    <div className="Xpp">
      <div className="container2">
        <h1 className='e21'>Affordable luxury meets<br/> friendly hospitality <br/>at <b3 className="b3" >Gcube Hostels</b3></h1>
        <p >
Experience utmost comfort and vibrancy at one of the finest student hostels nestled <br/>in the heart of Hyderabad.</p>
        <button data-bs-toggle="modal" data-bs-target="#exampleModal" onClick={()=>redirect()}>Book Now!</button>
      </div>
      
    </div>
  )
}

export default TopLevel