import React from 'react'
import Logo from '../Images/logo-removebg-ench.png'
import { Link } from 'react-router-dom'
import './Footer.css'
import {useForm} from 'react-hook-form'
function Footer() {

  let {register,handleSubmit,formState}=useForm()
  function submit(data){
    
    
  }
  return (
    
    <div className='text-center p-3'>
      {/* <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
    <div class="modal-content text-black">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="exampleModalLabel" style={{color:'#ffc554'}}>Check Availability</h1>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <form onSubmit={handleSubmit(submit)}>
      <div class="modal-body text-black text-start fw-bolder" style={{color:'#ffc554'}}>
        
          <label htmlFor="name">Your Name<span style={{color:'red'}}>*</span></label>
          <input type='text' placeholder='Enter Your Name' id='name' {...register('Name')} className='form-control'/>
          <label htmlFor="number">Mobile Number<span style={{color:'red'}}>*</span></label>
          <input type='number' placeholder='Enter your Mobile Number' id='number' {...register('Number')} className='form-control'/>
          <label htmlFor="email">Email<span style={{color:'red'}}>*</span></label>
          <input type='email' placeholder='Enter Your Email' id='email' {...register('Email')} className='form-control'/>
          <label htmlFor="name">Location of the Hostel<span style={{color:'red'}}>*</span></label>
          <select class="form-select" aria-label="Default select Location" {...register("Location")}>
              <option selected>Select Location</option>
              <option value="Pragathi Nagar">Pragathi Nagar</option>
              <option value="Simhapuri Colony">Simhapuri Colony</option>
              <option value="BVRIT">BVRIT</option>
          </select>
          <label htmlFor="name">Required from<span style={{color:'red'}}>*</span></label>
          <input type='date' id='name' {...register('Name')} className='form-control'/>
        
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-danger" data-bs-dismiss="modal">Close</button>
        <button type="submit" class="btn btn-success">Submit</button>
        
      </div>
      </form>
    </div>
  </div>
</div> */}
     <div className='text-white'><hr/></div>
      <div>
        <h4><img src={Logo} width='50px'/>Gcube Hostels</h4>   
      </div>
      <div className='footer'>
        <div className='b1footer'>
        <p><Link className='colord' to='/faqs'>FAQ'S</Link></p>
        <p><Link className='colord' to='/terms&conditions'>Terms & Conditions</Link></p>
        <p><Link className='colord' data-bs-toggle="modal" data-bs-target="#exampleModal">Check for Availability</Link></p>
        <p><Link className='colord' to='/contactus' >Contact Us</Link></p>
        </div>
        <div className='b1footer'>
          <p className='ces'>Locations</p>
        
          <p><Link className='colord' to='https://maps.app.goo.gl/XNpjSNe2fAxE2h757' target='_blank'>Pragathi Nagar Boys hostel</Link></p><p>         
             <Link className='colord' to='https://maps.app.goo.gl/tRjN3aXYX8pX4qMm6' target='_blank'>Simhapuri Colony Boys hostel</Link></p><p> 
          <Link  className='colord' to='https://maps.app.goo.gl/yRedNaPRzHM1rrmv6' target='_blank'>Simhapuri Colony Girls hostel</Link></p><p> 
          <Link className='colord' to='https://maps.app.goo.gl/kTkftyb5GKjPwm4b9' target='_blank'>Narsapur Boys hostel</Link></p>
        </div>
      </div>
    </div>
  )
}
<button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal">
  Launch demo modal
</button>
export default Footer