import './App.css';
import { createBrowserRouter,RouterProvider } from 'react-router-dom';
import RootLayout from './Components/RootLayout';
import Home from './Components/RootComponents/Home'
import Faq from './Components/FooterComponents/Faqs'
import Terms from './Components/FooterComponents/Terms'
import Contact from './Components/FooterComponents/Contactus';
import Mission from './Components/FooterComponents/OurMission'
function App() {
  const router = createBrowserRouter([
    { path:"/",
      element:<RootLayout/>,
      children :[
        {
          path:"/",
          element:<Home/>
        },
        {
          path:'/faqs',
          element:<Faq/>
        },
        {
          path:'/terms&conditions',
          element:<Terms/>
        },
        {
          path:'/ContactUs',
          element:<Contact/>
        },
        {
          path:'/mission',
          element:<Mission/>
        }
  ]}])
  return (
    <div className='App'>
      <RouterProvider router={router}/>
    </div>
  );
}

export default App;
