import React from 'react'

function Terms() {
  return (
    <div>
      <h1 className='' style={{color:'red'}}>Terms & Conditions</h1>
      <div className='container'>
        <ule className='fs-5'>
          <li>Please pay fee on or before 4 days of your due date to continue next month.
            </li>
          <li> Hostel management is not responsible for your personal belongings. Please keep your valuable belongings in your locker (Laptops, Phones, electronic items etc..)
            </li>
          <li>Fee once paid shall not be refunded.
            </li>
          <li> Prior notice of 15 Days should be given to management before vacating hostel. Otherwise the person need to pay 15 days amount to vacate if not informed before.
            </li>
          <li>
            Smoking and drinking alcohol is strictly prohibited in hostel rooms and management have full rights to vacate the violated person.
              </li>
          <li>Taking food into hostel rooms is not allowed.
                </li>
          <li>Management has full right to vacate a person in case of any misbehavior in hostel premises.</li>
          <li>Outsiders are not allowed into hostel rooms.
                </li>
          <li>This Hostel is Under CCTV Surivillance.</li>
        </ule>
      </div>
      
      </div>
  )
}

export default Terms