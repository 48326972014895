import React from 'react'
import Carousel from 'react-bootstrap/Carousel';
import first from '../Images/cor.jpg'
import Second from '../Images/2nd.jpg'
import Third from '../Images/3rd.jpg'
import fourth from '../Images/4th.jpg'
import recomended from '../Images/nearby.png'
import './Corosel.css'
import MiniCarousel from './MiniCarousel'
import vnr from '../Images/vnrvjiet.png'
import drk from '../Images/drk.png'
import bvrit from '../Images/BVRIT.png'
import klh from '../Images/klh.png'
import drreddy from '../Images/drreddy.svg'
function Corusel() {
  
  return (
    <div className='container'>
      <div class="wave">
      </div>
        <h1 className='text-white display-4 fw-bolder p-3'>Hostels located in Hyderabad</h1>
        
    <Carousel>
      <Carousel.Item interval={6000}>
        <div className='card'>
        <h1 className=' text-center text-black fs-1'>Our Main Hostel</h1>
          <p className='text-center text-black'>Opposite to Pragathi Nagar Kaman, Simhapuri Colony, Bachupally, Hyderabad.</p>
          <div className='text-center'><MiniCarousel images={[vnr,drk,klh,drreddy]} /></div>
          <div className='d-flex justify-content-around  flex-wrap'>
          <div>
              <img src={first} alt="" width="400rem" className='text-center' />
            </div>
          <div className=''>
          <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15217.185660764142!2d78.3936921!3d17.5410687!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb8f25611d1e4d%3A0x80f35ea84098c4a0!2sGcube%20Hostels!5e0!3m2!1sen!2sin!4v1707904648916!5m2!1sen!2sin" width="700rem" height="500rem"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>
        </div></div>
        <Carousel.Caption>
        
        </Carousel.Caption>
      </Carousel.Item>

      <Carousel.Item interval={6000}>
        <div className='card'>
        <h1 className='text-center text-black fs-1'>Our Boys Hostel</h1>
          <p className='text-center text-black'>Opposite to HS food court, Simhapuri Colony, Bachupally, Hyderabad.</p>
                    <div className='text-center'><MiniCarousel images={[vnr,drk,klh]} /></div>
          <div className='d-flex justify-content-around  flex-wrap'>
          <div>
              <img src={Second} alt="" width="400rem" className='text-center' />
            </div>
          <div className=''>
          <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d752.9639773647633!2d78.39323105612388!3d17.54336668860784!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb8faa4234476d%3A0x9b0730e2c6df5106!2sboys%20hostel!5e0!3m2!1sen!2sin!4v1707906197099!5m2!1sen!2sin" width="700rem" height="500rem"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>        </div>
        </div></div>
        <Carousel.Caption>
     
        </Carousel.Caption>
      </Carousel.Item>

      <Carousel.Item interval={6000}>
        <div className='card'>
        <h1 className='text-center text-black fs-1'>Our Girls Hostel</h1>
          <p className='text-center text-black'>Opposite to Crikbox, Simhapuri Colony,Bachupally, Hyderabad.</p>
          <div className='text-center'><MiniCarousel images={[vnr,drk,klh]} /></div>
          <div className='d-flex justify-content-around  flex-wrap'>
          <div>
              <img src={Third} alt="" width="400rem" className='text-center' />
            </div>
          <div className=''>
          <iframe src="https://www.google.com/maps/embed?pb=!1m10!1m8!1m3!1d752.9660971289304!2d78.39345190302316!3d17.54285644820996!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sin!4v1707906826405!5m2!1sen!2sin"  width="700rem" height="500rem" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>       
           </div></div></div>
        <Carousel.Caption>
       
        </Carousel.Caption>
      </Carousel.Item>


      <Carousel.Item interval={6000}>
        <div className='card'>
        <h1 className='text-center text-black fs-1'>Our Boys Hostel</h1>
          <p className='text-center text-black'>Opposite to TTD Kalyana Mandapam, Narsapur, Hyderabad.</p>
                    <div className='text-center'><MiniCarousel images={[bvrit]} /></div>
          <div className='d-flex justify-content-around  flex-wrap'>
          <div>
              <img src={fourth} alt="" width="400rem" className='text-center' />
            </div>
          <div className=''>
          <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15200.512647753261!2d78.2747893!3d17.7385994!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcc0bc337a84f0b%3A0xb902228a314732d1!2sGcube%20Hostels!5e0!3m2!1sen!2sin!4v1707907287450!5m2!1sen!2sin" width="700rem" height="500rem"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>           </div></div></div>
        <Carousel.Caption>
        
        </Carousel.Caption>
      </Carousel.Item>



















      {/* <Carousel.Item interval={500}>
      <div className='card'>
          <div className='d-flex'>
          <div>
<img src={cor} alt="" width="200rem" className='text-center' /></div>
          <div>
          <h1>Our Main Hostel</h1>
          <p className=''>Pragathi Nagar Kaman, KPHB Colony.</p>
        </div>
        </div></div>
      </Carousel.Item>
      <Carousel.Item>
      <div className='card'>
          <div className='d-flex'>
          <div>
<img src={cor} alt="" width="400rem" className='text-center' /></div>
          <div>
          <h1>Our Main Hostel</h1>
          <p className=''>Pragathi Nagar Kaman, KPHB Colony.</p>
        </div>
        </div></div>
      </Carousel.Item> */}
      {/* <div className='card'>
          <div className='d-flex'>
          <div>
<img src={cor} alt="" width="400rem" className='text-center' /></div>
          <div>
          <h1>Our Main Hostel</h1>
          <p className=''>Pragathi Nagar Kaman, KPHB Colony.</p>
        </div>
        </div></div> */}
    </Carousel>
      </div>
  )
}

export default Corusel