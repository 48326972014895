import React from 'react'
import Logo from '../Images/logo-removebg-ench.png'

function SecondComponent() {
  return (
    <div><div className="text-center container p-3">
    <header >
      <div className="image-container">
      <img src={Logo} alt="Gcube Hostels logo" width='200rem' className="App-logo" /></div>
      <p>
        Welcome to Gcube Hostels, where comfort meets affordability.
      </p>
    </header>
    <main className="App-main">
      <section className="App-about">
        <h1>Our Mission</h1>
        <p>
        Gcube Hostels, nestled in Hyderabad, Telangana, caters primarily to students near BTech colleges. Offering secure and friendly accommodation, we ensure a comfortable stay with clean rooms, supportive staff, and a central location. Simplifying student life, our hostel provides convenience and peace of mind, making it an ideal choice for those embarking on their academic journey. We're dedicated to fostering a welcoming environment that feels like home away from home for our residents. Get your bed in one of the best student hostels
        </p>
      </section>
    </main>
  </div></div>
  )
}

export default SecondComponent