import React, {useEffect} from 'react'
import pic1 from '../Images/pic1.jpg'
import pic2 from '../Images/pic2.jpg'
import pic3 from '../Images/pic3.jpg'
import pic4 from '../Images/pic6.jpg'
import pic5 from '../Images/PXL_20231231_183011289.jpg'
import pic6 from '../Images/PXL_20231231_183013714.jpg'
import pic7 from '../Images/PXL_20231231_183420368.jpg'
import pic8 from '../Images/PXL_20231231_183545809.jpg'
import pic9 from '../Images/PXL_20231231_183912884.MP.jpg'
import pic10 from '../Images/pic7.jpg'
import pic11 from '../Images/pic8.jpg'
import AOS from 'aos'
import 'aos/dist/aos.css'
function Gallery() {
    useEffect(()=>{
        AOS.init({duration: 2000})
    },[]);
  return (
    <div>
      <h1 className='text-white display-4 fw-bolder p-3'>Gallery</h1>
      <div className='container-fluid row '>
        
        <div className='text-center col m-3'>
            <div className="" data-aos="flip-right">
                <img src={pic1} alt=""  height="200px"width="300px" className='rounded-5' />
            </div>
        
        </div>
        
        
        

        <div className='text-center col m-3'>
            <div className="" data-aos="fade-down">
                <img src={pic5} alt="" height="200px" width="300px" className='rounded-5' />
            </div>
           
        </div>

        <div className='text-center col m-3'>
            <div className="" data-aos="flip-left">
                <img src={pic6} alt=""  height="200px"width="300px" className='rounded-5' />
            </div>
           
        </div>
        <div className='d-flex flex-wrap align-items-center'>
        <div className='text-center col m-3'>
            <div className="" data-aos="flip-right">
                <img src={pic2} alt="" height="250px" width="300px" className='rounded-5' />
            </div>
        </div>
        <div className='text-center col m-3'>
            <div className="" data-aos="zoom-in">
                <img src={pic10} alt="" height="250px" width="300px" className='rounded-5' />
            </div>
        </div>
        <div className='text-center col m-3 ' >
            <div className="" data-aos="zoom-in">
                <img src={pic11} alt="" height="210px" width="320px" className='rounded-5' />
            </div>
        </div>

        <div className='text-center col m-3'>
            <div className="" data-aos="flip-left">
                <img src={pic4} alt="" height="250px" width="300px" className='rounded-5' />
            </div>
           
        </div>
        </div>
        
    
        <div className='text-center col m-3'>
            <div className="" data-aos="flip-right">
                <img src={pic7} alt=""  height="400px"width="300px" className='rounded-5' />
            </div>
           
        </div>
        <div className='text-center col m-3'>
            <div className="" data-aos="fade-up">
                <img src={pic9} alt=""  height="400px"width="300px" className='rounded-5' />
            </div>
           
        </div>
        <div className='text-center col m-3'>
            <div className="" data-aos="flip-left">
                <img src={pic8} alt=""  height="400px"width="300px" className='rounded-5' />
            </div>
           
        </div>

       
      

    </div>
    </div>
  )
}

export default Gallery