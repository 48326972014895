import React from 'react'
import MidComponent from '../HomeComponents/MidComponent'
import Corosel from '../HomeComponents/Corusel'
import TopLevel from '../HomeComponents/TopLevel'
import SecondComponent from '../HomeComponents/SecondComponent'
import Services from '../HomeComponents/Services'
import Gallery from '../HomeComponents/Gallery'
import './Home.css'

import { useState, useEffect,useRef } from 'react';
import Reviews from '../HomeComponents/Reviews'

function Home() {
  
  return (
    <div >
      <div><TopLevel/></div>
      <div><SecondComponent/></div>

      <div><Services/></div>
      <div><Corosel/></div>

      <div><Gallery imageUrl="https://drscdn.500px.org/photo/188823103/w%3D440_h%3D440/v2?webp=true&v=3&sig=af23265ed9beaeeeb12b4f8dfed14dd613e5139495ba4a80d5dcad5cef9e39fd"/></div>

      <Reviews/>
      

    


    </div>
  )
}

export default Home