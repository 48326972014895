import React, {useEffect} from 'react'
import { Card, CardText } from 'react-bootstrap'
import Room from '../Images/room.png'
import Secure from '../Images/secure.png'
import Wifi from '../Images/wifi.png'
import Laundry from '../Images/laundry.png'
import Food from '../Images/grateFood.png'
import Community from '../Images/community.png'
import Events from '../Images/events.png'
import AOS from 'aos'
import 'aos/dist/aos.css'
function Services() {
    useEffect(()=>{
        AOS.init({duration: 3000})
    },[]);
  return (
    <div>
        <h1 className='text-white display-4 fw-bolder p-3'>Services</h1>
        <div className='container-fluid row '>
        
            <div className='text-center col'>
                <div className="" data-aos="zoom-in">
                    <img src={Room} alt="" width="300px" className='rounded-5' />
                </div>
                <div className='text-center'>
                    <p className='fst-italic fs-1 fw-semibold'>Comfortable accommodations</p>
                </div>

            </div>
            <div className='text-center col'>
                <div className="" data-aos="zoom-in">
                    <img src={Secure} alt="" width="300px" className='rounded-5' />
                </div>
                <div className='text-center'>
                    <p className='fst-italic fs-1 fw-semibold'>24/7 Security</p>
                </div>

            </div>
            <div className='text-center col'>
                <div className="" data-aos="zoom-in">
                    <img src={Wifi} alt="" width="300px" className='rounded-5' />
                </div>
                <div className='text-center'>
                    <p className='fst-italic fs-1 fw-semibold'>Free Wi-fi</p>
                </div>

            </div>
            <div className='text-center col'>
                <div className="" data-aos="zoom-in">
                    <img src={Laundry} alt="" width="300px" className='rounded-5' />
                </div>
                <div className='text-center'>
                    <p className='fst-italic fs-1 fw-semibold'>Laundry facilities</p>
                </div>

            </div>

            <div className='text-center col'>
                <div className="" data-aos="zoom-in">
                    <img src={Food} alt="" width="300px" className='rounded-5' />
                </div>
                <div className='text-center'>
                    <p className='fst-italic fs-1 fw-semibold'>Tasty Food</p>
                </div>

            </div>

            <div className='text-center col'>
                <div className="" data-aos="zoom-in">
                    <img src={Community} alt="" width="300px" className='rounded-5' />
                </div>
                <div className='text-center'>
                    <p className='fst-italic fs-1 fw-semibold'>Friendly community</p>
                </div>

            </div>
            <div className='text-center col'>
                <div className="" data-aos="zoom-in">
                    <img src={Events} alt="" width="300px" className='rounded-5' />
                </div>
                <div className='text-center'>
                    <p className='fst-italic fs-1 fw-semibold'>Events and activities</p>
                </div>

            </div>

        </div>
    </div>
  )
}

export default Services