import React, { useState } from 'react';

import '../App.css'
function Message(props) {
  const [status, setStatus] = useState(null);

  const handleSuccess = () => {
    setStatus('your request was received! we will contact you soon!');
    setTimeout(() => {
      setStatus(null);
    }, 3000); // Clear the notification after 3 seconds
  };

  const handleFailure = () => {
    setStatus('Something Went wrong! Try resubmitting!');
    setTimeout(() => {
      setStatus(null);
    }, 3000); // Clear the notification after 3 seconds
  };
  return (
    <div className=''>
        <div>
      {status === 'success' && (
        <div style={{ backgroundColor: 'green', color: 'white', padding: '10px' }}>
          Success! Your action was completed.
        </div>
      )}
      {status === 'failure' && (
        <div style={{ backgroundColor: 'red', color: 'white', padding: '10px' }}>
          Error! Something went wrong.
        </div>
      )}
    </div>
    </div>
  )
}

export default Message;