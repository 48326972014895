import React from 'react'
import { RouterProvider } from 'react-router-dom'
import Footer from './RootComponents/Footer'
import NavBar from './RootComponents/NavBar'
import {Outlet} from 'react-router-dom'
import  Mesage  from './Message'
function RootLayout() {

  return (
    <div style={{maxWidth:"100vw"}}>
    <div className='p-3'><NavBar /></div>
    <div style={{minHeight:"100vh"}}>
      <Mesage/>
    <Outlet />
    </div>
    <Footer />
    </div>
  )
}

export default RootLayout