// OurMission.js

import React from 'react';
import { motion } from 'framer-motion';

const OurMission = () => {
  return (
    <div className="our-mission">
      <h1>Core Values</h1>
      <div className="mission-content">
        <motion.div
          className="mission-item"
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1 }}
        >
          <h2>Affordability & Value</h2>
          <p>
Budget Bliss at Gcube!
Shine bright without burning a hole in your pocket! Gcube Hostel offers sparkling clean stays at prices that make you smile. No need to compromise comfort – get great value for your money and fuel your adventures! Check out Gcube – your budget-friendly haven awaits!</p>
        </motion.div>
        <motion.div
          className="mission-item"
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1.2 }}
        >
          <h2>Cleanliness & Wellbeing</h2>
          <p>
We at Gcube Hostel aren't just about tidy spaces, we're about your well-being! From sparkling clean rooms to sanitized everything, we care for your health and comfort. Forget stuffy hostels, here you get a fresh start for your adventures, a place to breathe easy, relax, and truly enjoy. We've got your well-being covered, come join the Gcube family!</p>
        </motion.div>
        {/* Add more mission items with animations as needed */}
      </div>
      <div className="mission-image">
        {/* Your mission-related image */}
      </div>
    </div>
  );
};

export default OurMission;
