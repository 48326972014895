import React from 'react'
import { useState } from 'react';
import './Faqs.css'
function Faqs() {
  const faqs = [
    
      {
      question: 'How can I apply for a room at your hostel?',
      answer: 'To apply for a room at our hostel, you can visit our website or contact us directly to inquire about room availability.'
      },
      {
      question: 'What room options do you offer at your hostel?',
      answer: 'We provide a range of room options at our  hostel, including single rooms, double-sharing rooms, and triple-sharing rooms, four-sharing rooms, five-sharing rooms, six-sharing rooms. You can choose the type of room that suits your preferences and needs.'
      },
      {
      question: 'Do you offer meal plans for residents?',
      answer: 'Yes, we offer meal plans for residents, which typically include options for breakfast, lunch, and dinner. You can select the meal plan that aligns with your dietary requirements.'
      },
      {
      question: 'Are bed linens and towels provided for residents?',
      answer: 'We provide bed linens for residents. However, you may need to bring your own towels, so be sure to check our specific policies.'
      },
      {
      question: 'What amenities are available at your  hostel?',
      answer: 'Our  hostel offers amenities such as Wi-Fi,Food, laundry facilities, and a common lounge area to enhance your living experience.'
      },
      {
      question: 'Is there a curfew or specific entry/exit times for residents?',
      answer: 'we have guidelines regarding entry and exit times for security and safety reasons. We will provide you with these details when you join our hostel.'
      },
      {
      question: 'What is the rent and security deposit for a room at your  hostel?',
      answer: 'The rent and security deposit for rooms at our  hostel may vary depending on factors like room type and location. Contact us to get accurate pricing information and inquire about any available discounts.'
      },
      {
      question: 'Is your  hostel suitable for students or working professionals?',
      answer: 'Our  hostel caters to both students and working professionals, aiming to provide a peaceful and conducive living environment for all residents.'
      },
      {
      question: 'What is your guest policy for the  hostel?',
      answer: 'We have a guest policy in place to ensure the safety and security of our residents. Visitors may be allowed during specific hours; for the most up-to-date policy, please check with our management team.'
      },
      {
      question: 'Do you provide regular cleaning and maintenance services?',
      answer: 'Yes, we have a regular cleaning and maintenance schedule in place to keep the premises in good condition. If you have specific concerns, please feel free to inform our management team.'
      },
      {
      question: 'What is your cancellation policy if I need to leave the  hostel early?',
      answer: "Our cancellation policy may vary, so it's best to discuss this with us when you apply. We will be happy to provide you with the relevant details."
      },
      {
      question: 'Is your  hostel pet-friendly?',
      answer: 'While we generally do not allow pets in our  hostel, exceptions can be made in specific cases. Please contact us in advance to discuss your unique situation.'
      },
      {
        question: 'What are the typical lease durations for rooms in your hostel?',
        answer: 'The lease durations in our PG hostel can vary. We offer both short-term and long-term rental options, and the lease duration will be specified in your rental agreement.'
        },
        {
        question: 'How can I pay my rent and other charges at the hostel?',
        answer: 'You can pay your rent and other charges using various payment methods, including cash, online transfers, or checks. We will provide you with details on payment options during the check-in process.'
        },
        {
        question: 'Do you offer any security measures for residents, such as CCTV surveillance or security personnel?',
        answer: 'Yes, we prioritize the safety and security of our residents. We have security measures in place, which may include CCTV surveillance and/or security personnel, to ensure a secure living environment.'
        },
        {
        question: 'What happens if I need maintenance or have a maintenance request?',
        answer: 'If you require maintenance or have a maintenance request, please inform our management team or use our designated channels. We will address the issue promptly to ensure your comfort.'
        },
        {
        question: 'Is there any flexibility in the check-in and check-out times for residents?',
        answer: 'We understand that residents may have unique schedules. While we have standard check-in and check-out times, we can provide flexibility in certain cases. Please communicate your specific needs with us in advance.'
        }
        
        
        
        
        
        
      
      
      
      
    // Add more FAQs here
  ];
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleFAQ = (index) => {
    if (activeIndex === index) {
      setActiveIndex(null);
    } else {
      setActiveIndex(index);
    }
  };
  return (
    <div className="faq-container">
      <h1>Frequently Asked Questions</h1>
      <div className="faq-list">
        {faqs.map((faq, index) => (
          <div key={index} className="faq-item">
            <div
              className={`faq-question ${index === activeIndex ? 'active' : ''}`}
              onClick={() => toggleFAQ(index)}
           style={{color:'black'}} >
              {faq.question}
            </div>
            {index === activeIndex && (
              <div className="faq-answer">{faq.answer}</div>
            )}
          </div>
        ))}
      </div>
    </div>
  )
}

export default Faqs