import React from 'react'
import './ContactUs.css'
import insta from '../Images/insta.png'
import call from '../Images/call.jpg'
import whatsapp from '../Images/whatsapp.png'
import { motion } from 'framer-motion';
import './OurMission.css'
function Contactus() {
    const managerDetails = {
        name: 'Srinivas Reddy',
        email: 'gcubehostels@gmail.com',
        phone: '6304944417',
        Alternate:'6302214608',
      };
  return (
    <div className='d-flex justify-content-center'>
    <motion.div
          className="mission-item"
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1 }}
        >
    <div className="contact-container">
      
      <h1>Contact Us</h1>
      <div className="manager-details" style={{color:'#ffc554'}}>
        <h2 style={{color:'#ffc554'}}>Manager Details</h2>
        <div className='container '>
        <p className='text-white '>Name: {managerDetails.name}</p>
        <p className='text-white'>Email: {managerDetails.email}</p>
        <p className='text-white'>Phone: <a href={`tel:${managerDetails.phone}`}>{managerDetails.phone}</a></p>
        <p className='text-white'>Alternate Phone: <a href={`tel:${managerDetails.Alternate}`}>{managerDetails.Alternate}</a></p>

        </div>
      </div>
      <div className="contact-links">
        <h2 style={{color:'#ffc554'}}>Contact Links</h2>
        
        <ul className='pe-3'>
        
            <a href={`https://api.whatsapp.com/send?phone=91${managerDetails.phone}`} className='m-2' target='_blank'><img src={whatsapp} width='30px'/></a>
        
        
            <a href={`tel:${managerDetails.phone}`}><img src={call} target='_blank'className='m-2' width='30px'/></a>
        
          <a href='https://instagram.com/_g_cube_pg_?igshid=OGQ5ZDc2ODk2ZA==' target='_blank'><img src={insta} width='30px' target='_blank' className='m-2'/></a>
          {/* Add more contact links as needed */}
        </ul>
        <p>contact us for faster response</p>
      </div>
      
    </div>
    </motion.div>
    </div> 
  )
}

export default Contactus