import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import rew1 from '../Images/rewiew1.png';
import rew2 from '../Images/rewiew2.png';
import rew3 from '../Images/rewiew3.png';
import rew4 from '../Images/rewiew4.png';
import rew5 from '../Images/rewiew5.png';
import rew6 from '../Images/rewiew6.png';
import rew7 from '../Images/rewiew7.png';

import star from '../Images/star.png';
import './Reviews.css';

function Reviews() {
  const reviews = [
    { id: 1, src: rew1, alt: 'Review 1' },
    { id: 2, src: rew2, alt: 'Review 2' },
    { id: 3, src: rew3, alt: 'Review 3' },
    { id: 4, src: rew4, alt: 'Review 4' },
    { id: 5, src: rew5, alt: 'Review 5' },
    { id: 6, src: rew6, alt: 'Review 6' },
    { id: 7, src: rew7, alt: 'Review 7' },


    // Add more reviews as needed
  ];

  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div className="container text-center pt-5">
      <div className="d-flex justify-content-center align-items-center">
        <h4 className="display-2">Rated 5 Star</h4>
        <img src={star} width="100rem" alt="" />
      </div>

      <div className="carousel-container container justify-content-around">
        <Slider {...settings}>
          {reviews.map((review) => (
            <div className="container" key={review.id}>
              <img src={review.src} alt={review.alt} width="300vw" className="" />
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
}

export default Reviews;
