import React, { useState } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import './Navbar.css';
import Logo from '../Images/logo-removebg-ench.png';
import { useForm } from 'react-hook-form';
import Message from '../Message';

function NavBar() {
  const { register, handleSubmit } = useForm();
  const [isModalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [mess, setMess] = useState("Mailing you the Response...");
  const navigate = useNavigate();

  

  const handleFormSubmit = (data) => {
    setLoading(true);
    axios.post(`https://gcubemailer.cyclic.app/mailer/${data.Email}`, data)
      .then((resp) => {
        if (resp.data.message === "Success") {
          setTimeout(() => {
          }, 1000);

          setMess("Your response has been Received!");

          setTimeout(() => {
          }, 2000);
          setMess("Redirecting you to the contact page...");

          setTimeout(() => {
          }, 2000);
          navigate('/contactUs');

          window.location.reload();

          setModalOpen(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const handleModalOpen = () => {
    setModalOpen(true);
  };

  return (
    <div>
      <Message />
      <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{ display: isModalOpen ? 'block' : 'none' }}>
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content text-black">
            <div className="modal-header">
            <h1 class="modal-title fs-5" id="exampleModalLabel">Check Availability at the best hostel</h1>              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            {loading && (<div><p>{mess}</p><div className="loader"></div></div>)}
            <form onSubmit={handleSubmit(handleFormSubmit)}>
              <div className="modal-body text-black text-start fw-bolder" style={{ color: '#ffc554' }}>
                <label htmlFor="name">Your Name<span style={{ color: 'red' }}>*</span></label>
                <input type='text' placeholder='Enter Your Name' id='name' {...register('Name', { required: true })} className='form-control' />
                <label htmlFor="number">Mobile Number<span style={{ color: 'red' }}>*</span></label>
                <input type='number' placeholder='Enter your Mobile Number' id='number' {...register('Number', { required: true, minLength: 10, maxLength: 10 })} className='form-control' />
                <label htmlFor="email">Email<span style={{ color: 'red' }}>*</span></label>
                <input type='email' placeholder='Enter Your Email' id='email' {...register('Email', { required: true })} className='form-control' />
                <label htmlFor="name">Location of the Hostel<span style={{ color: 'red' }}>*</span></label>
                <select className="form-select" aria-label="Default select Location" {...register("Location", { required: true })}>
                  <option selected>Select Location</option>
                  <option value="Pragathi Nagar Hostel">Pragathi Nagar Hostel</option>
                  <option value="Simhapuri Colony Hostel">Simhapuri Colony Hostel</option>
                  <option value="Narsapur Hostel">Narsapur Hostel</option>
                  <option value="Girls Hostel, Simhapuri Colony">Girls Hostel</option>
                </select>
                <label htmlFor="type">Room type<span style={{ color: 'red' }}>*</span></label>
                <select className="form-select" aria-label="Default select Location" {...register("Type", { required: true })}>
                  <option selected>Select Room type</option>
                  <option value="1">Single Room</option>
                  <option value="2">2 Sharing</option>
                  <option value="3">3 Sharing</option>
                  <option value="4">4 Sharing</option>
                  <option value="5">5 Sharing</option>
                  <option value="6">6 Sharing</option>
                </select>
                <label htmlFor="date">Required from<span style={{ color: 'red' }}></span></label>
                <input type='date' id='date' {...register('Date')} className='form-control' />
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-danger" data-bs-dismiss="modal">Close</button>
                <button type="submit" className="btn btn-success">Submit</button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <nav className="navbar navbar-expand-lg bg-body-tertiary bg-primary" data-bs-theme='dark' id='el'>
        <div className="container">
          <div>
            <a className="navbar-brand" href="/"><img src={Logo} width='50px' alt="logo" /></a>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo01" aria-controls="navbarTogglerDemo01" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
          </div>
          <div>
            <div className="collapse navbar-collapse" id="navbarTogglerDemo01">
              <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                <li className="nav-item">
                  <Link className="nav-link active" to='/' style={{ 'color': '#ffc554' }}>Home</Link>
                </li>
                <li>
                  <Link className='nav-link active' data-bs-toggle="modal" data-bs-target="#exampleModal" onClick={handleModalOpen} style={{ 'color': '#ffc554' }} aria-expanded="true">Check Availability</Link>
                </li>
                <li>
                  <Link className='nav-link active' to='/contactUs' style={{ 'color': '#ffc554' }}>Contact Us</Link>
                </li>
                <li>
                  <Link className="nav-link active" to='/Mission' style={{ 'color': '#ffc554' }}>Our Values</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>
    </div>
  )
}

export default NavBar;
